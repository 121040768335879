import classnames from "classnames";
import React, { useState, useCallback, useEffect, useRef } from "react";

import logoSprite from "@assets/images/logo-sprite.png";
import ProjectsHero from "@components/ProjectsHero";
import Intro from "@components/Intro";

import "./style.css";

const HomepageHeroContainer = () => {
  const innerRef = useRef(null);

  // Logo animation
  const logoCanvas = useRef(null);
  const logoCanvasCtx = useRef(null);
  const logoSpriteImage = useRef(null);
  const logoAnimation = useRef({
    raf: null,
    currentFrame: 0,
    framesCount: 125,
    millis: 1000 / 24,
    lastRenderTime: 0,
    columns: 10,
    tileWidth: 408,
    tileHeight: 408,
    isProjectsHeroLoading: true,
  });

  const [isLoading, setIsLoading] = useState({
    projectsHero: true,
    logoAnimation: true,
    isTransitioned: false,
  });
  const handleIsProjectsHeroLoaded = useCallback(() => {
    logoAnimation.current.isProjectsHeroLoading = false;
    setIsLoading(isLoading => ({ ...isLoading, projectsHero: false }));
  }, [setIsLoading]);

  const renderLogoAnimationFrame = useCallback((frame = 0) => {
    if (frame < 0 || frame >= logoAnimation.current.framesCount) {
      return;
    }

    const sourceX =
      (frame % logoAnimation.current.columns) * logoAnimation.current.tileWidth;
    const sourceY =
      Math.floor(frame / logoAnimation.current.columns) *
      logoAnimation.current.tileHeight;

    logoCanvasCtx.current.clearRect(
      0,
      0,
      logoCanvas.current.width,
      logoCanvas.current.height
    );
    logoCanvasCtx.current.drawImage(
      logoSpriteImage.current,
      // source
      sourceX,
      sourceY,
      logoAnimation.current.tileWidth,
      logoAnimation.current.tileHeight,
      // destination
      0,
      0,
      logoCanvas.current.width,
      logoCanvas.current.height
    );
  }, []);

  const animateLogo = useCallback(
    t => {
      if (
        logoSpriteImage.current.complete &&
        t - logoAnimation.current.lastRenderTime > logoAnimation.current.millis
      ) {
        const newFrame =
          logoAnimation.current.currentFrame >=
          logoAnimation.current.framesCount - 1
            ? 0
            : logoAnimation.current.currentFrame + 1;

        /*
        if (newFrame === 0) {
          // animation complete
          logoAnimation.current.raf = null;

          setIsLoading({
            projectsHero: false,
            logoAnimation: false,
          });
          return;
        }
        */

        renderLogoAnimationFrame(newFrame);
        logoAnimation.current.lastRenderTime = t;
        logoAnimation.current.currentFrame = newFrame;
      }

      if (logoAnimation.current.raf) {
        logoAnimation.current.raf = requestAnimationFrame(animateLogo);
      }
    },
    [renderLogoAnimationFrame]
  );

  useEffect(() => {
    if (logoCanvas.current) {
      logoCanvasCtx.current = logoCanvas.current.getContext("2d");
      logoSpriteImage.current = new Image();
      logoSpriteImage.current.src = logoSprite;

      // on setup render first frame
      renderLogoAnimationFrame(0);
    }
  }, [renderLogoAnimationFrame]);

  useEffect(() => {
    if (isLoading.logoAnimation && !logoAnimation.current.raf) {
      logoAnimation.current.raf = requestAnimationFrame(animateLogo);
    }
  }, [animateLogo, isLoading.logoAnimation]);

  const handleInnerTransitionEnd = useCallback(e => {
    if (e.propertyName === "clip-path") {
      setIsLoading(isLoading => ({ ...isLoading, isTransitioned: true }));
    }
  }, []);
  useEffect(() => {
    innerRef.current.addEventListener(
      "transitionend",
      handleInnerTransitionEnd
    );

    return () => {
      innerRef.current.removeEventListener(
        "transitionend",
        handleInnerTransitionEnd
      );
    };
  }, [handleInnerTransitionEnd]);

  return (
    <div
      className={classnames("homepage-hero-container header-color--light", {
        "homepage-hero-container--loading":
          false && (isLoading.projectsHero || isLoading.logoAnimation),
        "homepage-hero-container--loaded": true || isLoading.isTransitioned,
      })}
    >
      <div
        className={classnames("homepage-hero-container__loader", {
          "homepage-hero-container__loader--active": isLoading.logoAnimation,
        })}
      >
        <canvas width="408" height="408" ref={logoCanvas} />
      </div>

      <div ref={innerRef} className="homepage-hero-container__inner">
        <div className="homepage-hero-container__projects-hero">
          <ProjectsHero
            loading={isLoading.projectsHero || isLoading.logoAnimation}
            onLoaded={handleIsProjectsHeroLoaded}
          />
        </div>

        <div className="homepage-hero-container__intro">
          <Intro />
        </div>
      </div>
    </div>
  );
};

export default HomepageHeroContainer;
