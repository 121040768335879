import React from "react";
import { graphql } from "gatsby";

import ContactLine from "@components/ContactLine";
import Footer from "@components/Footer";
import HomepageHeroContainer from "@components/HomepageHeroContainer";
import SEO from "@components/seo";

import useUIColorCheck from "@utils/useUIColorCheck";

import "./home/style.css";

const IndexPage = ({ data, transitionStatus }) => {
  useUIColorCheck([transitionStatus === "entered"]);

  return (
    <>
      <div className="page home-page">
        <SEO title="Roll Studio" />

        <HomepageHeroContainer />
        {/*<ContactLine />*/}
      </div>

      {/*<Footer />*/}
    </>
  );
};

export default IndexPage;

export const query = graphql`
  {
    wpgraphql {
      projects {
        nodes {
          id
          uri
          title
          slug

          acf {
            featuredInHomepageHero
            homepageHeroDescription
            homepageHeroTitle
            model {
              mediaItemUrl
            }
            modelScale
          }
        }
      }
    }
  }
`;
