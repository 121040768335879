import classnames from "classnames";
import React, { useCallback, useRef, useState, useEffect } from "react";

import FadeLink from "@components/FadeLink";
import Grid from "@components/Grid";
import ScrollIcon from "@components/ScrollIcon";
//import Grow from "@components/CursorEffects/Grow";
//import ReverseChars from "@components/CursorEffects/ReverseChars";
//import Wobble from "@components/CursorEffects/Wobble";

import "./style.css";

const SCROLL_MAX = 28 + 500;
const SCROLL_HINT_DAMPING = 0.1;

const HomepageIntro = () => {
  const handleRef = useRef(null);
  const handleBGRef = useRef(null);
  const handleOffseTop = useRef(0);
  const [isScrollHintHidden, setIsScrollHintHidden] = useState(false);

  const scrollHintAnimationRef = useRef({
    raf: null,
    currentY: 0,
    targetY: 0,
  });
  const scrollHintAnimate = useCallback(() => {
    const { currentY, targetY } = scrollHintAnimationRef.current;

    // calc next y
    const nextY = currentY + (targetY - currentY) * SCROLL_HINT_DAMPING;
    scrollHintAnimationRef.current.currentY = nextY;

    // update path d
    handleBGRef.current.setAttribute(
      "d",
      `M0 24C 30 24, 20 ${nextY}, 60 ${nextY}C 100 ${nextY}, 90 24, 120 24V72H0z`
    );

    if (Math.abs(nextY - targetY) > 0.1) {
      scrollHintAnimationRef.current.raf = requestAnimationFrame(
        scrollHintAnimate
      );
    } else {
      scrollHintAnimationRef.current.raf = null;
      handleBGRef.current.setAttribute(
        "d",
        `M0 24C 30 24, 20 ${targetY}, 60 ${targetY}C 100 ${targetY}, 90 24, 120 24V72H0z`
      );
    }
  }, []);
  const hideScrollHint = useCallback(() => {
    scrollHintAnimationRef.current.targetY = 24;
    if (!scrollHintAnimationRef.current.raf) {
      requestAnimationFrame(scrollHintAnimate);
    }
  }, [scrollHintAnimate]);
  const showScrollHint = useCallback(() => {
    scrollHintAnimationRef.current.targetY = 0;
    if (!scrollHintAnimationRef.current.raf) {
      requestAnimationFrame(scrollHintAnimate);
    }
  }, [scrollHintAnimate]);

  const handleResize = useCallback(() => {
    handleOffseTop.current = handleRef.current.offsetTop;

    let offsetParent = handleRef.current.offsetParent;
    while (offsetParent) {
      handleOffseTop.current += offsetParent.offsetTop;
      offsetParent = offsetParent.offsetParent;
    }
  }, []);
  const handleScroll = useCallback(() => {
    const y = handleOffseTop.current - window.scrollY;

    if (!isScrollHintHidden && y < SCROLL_MAX) {
      hideScrollHint();
      setIsScrollHintHidden(true);
    } else if (isScrollHintHidden && y >= SCROLL_MAX) {
      showScrollHint();
      setIsScrollHintHidden(false);
    }
  }, [isScrollHintHidden]);
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <div className="homepage-intro">
      <div ref={handleRef} className="homepage-intro__handle">
        <ScrollIcon
          className={classnames("homepage-intro__handle-icon", {
            "homepage-intro__handle-icon--hidden": isScrollHintHidden,
          })}
        />

        <svg viewBox="0 0 120 72" className="homepage-intro__handle-bg">
          <path
            ref={handleBGRef}
            d="M0 24C 30 24, 20 0, 60 0C 100 0, 90 24, 120 24V72H0z"
            fill="black"
          />
        </svg>
      </div>

      <Grid.Container
        fluid
        className={classnames("homepage-intro__inner", {
          "homepage-intro__inner--active": isScrollHintHidden,
        })}
      >
        <Grid.Row>
          <Grid.Cell>
            <div className="homepage-intro__text">
              After years of crafting digital experiences, it's time to say
              goodbye. Thanks you for being part of our journey.
              {/*Roll Studio shapes the future of market-leading brands. We
              transform outdated customer-facing software into pioneering,
              beautifully crafted solutions that lead the evolution of web
              technologies and exceed customer expectations.*/}
              {/* Roll Studio transforms the legacy software of market-leading
              brands into beautifully crafted, pioneering solutions that shape
              the evolution of web technologies and set the standard for
              exceptional customer experience. */}
              {/* <br />
              We have <Wobble>shifted</Wobble> digital culture over a decade of
              play and progression.
              <br />
              Trusted by dynamic companies to surpass expectation through
              digital products and experiences that drive <Grow>
                growth
              </Grow>{" "}
              and real <ReverseChars>change.</ReverseChars> */}
            </div>
          </Grid.Cell>
        </Grid.Row>
        <Grid.Row>
          <Grid.Cell>
            <div className="homepage-intro__cta">
              Every digital story has an ending - Roll Studio's is here
            </div>
          </Grid.Cell>
        </Grid.Row>
      </Grid.Container>
    </div>
  );
};

export default HomepageIntro;
